import { getAccessToken } from '@/utils/amplify/getAccessTokens';
import sendSentry from '@/utils/sentry';

const customFetch: (input: RequestInfo, init?: RequestInit, token?: string) => Promise<Response> =
  async (input, init) => {
    try {
      const accessToken = await getAccessToken();

      init = init || {};
      init.headers = {
        ...init.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    } catch (error) {
      sendSentry(error);
      console.error('Error getting session', error);
    }

    return fetch(input, init);
  };

export default customFetch;
